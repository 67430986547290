@import "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body{
  background-color: #1B1C1E !important;
  color: #FAFAFA;
  font-family: 'Poppins';
}

h4{
  font-weight: normal !important;
}

hr{
  background-color: #fff !important;
  height: 2px;
  width: 100%;
}

.App {
  text-align: center;
}


.cover-container {
  height: 100vh !important;
}

.masthead{
  .inner{
    display: flex;
    align-items: center;
    .logo{
      width: 50px;
    }
  }

  .nav{
    .nav-link{
      color: #FAFAFA;

      &:active, &:focus{
        color: #FAFAFA;
      }

      &.active, &:hover{
        color: #7885FE !important;
        cursor: pointer;
      }
    }
  }
}

.content{
  font-family: 'Poppins', sans-serif !important;
}

.heading{
  .logo-heal{
    width: 100px;
  }
}

.nft-card{
  background-color: #2E2E2E;
  max-width: 600px;
}

.MuiContainer-root{
  margin-top: 10px !important;

  .MuiPaper-root{
    background-color: transparent !important;
    box-shadow: none !important;
  }
} 

.MuiSnackbar-root{
  background-color: #1B1C1E;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}